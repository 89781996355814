.container {
  background: #fff;
  position: relative;
}

.ttl {
  border-bottom: 1px solid #eaeaea;
  font-size: 16px;
  margin: 0;
  padding: 16px;
  text-align: center;
  position: sticky;
  top: 0;
  z-index: 10;
  background: #fff;
}

.box {
  box-sizing: border-box;
  margin: 0 auto;
  padding: 32px 24px 48px;
  max-height: 80vh;
  overflow-y: scroll;
  overflow-x: hidden;
}

.box::-webkit-scrollbar {
  display: none;
}

.result {
  background: #f8f8f8;
  border-radius: 8px;
  box-sizing: border-box;
  padding: 24px;
}

.genre {
  color: #9281ff;
  font-size: 14px;
  margin: 0;
}

.name {
  font-size: 18px;
  font-weight: normal;
  margin: 8px 0;
}

.pay {
  display: flex;
  align-items: center;
  justify-content: space-between;
  list-style: none;
  margin: 0;
  padding: 0;
}

.amount {
  font-size: 14px;
}

.basePrice {
  font-size: 14px;
  padding-right: 1rem;
}

.price {
  font-size: 24px;
}

.contents {
  border-top: 1px dashed #c4c4c4;
  margin: 16px 0 0;
  padding-top: 16px;
  display: flex;
}

.dttl {
  color: #999;
  font-size: 14px;
}

.item {
  margin: 0;
}

.list {
  color: #999;
  font-size: 14px;
  line-height: 160%;
  list-style: none;
}

.subttl {
  text-align: center;
  margin-top: 0;
  font-size: 16px;
}

.subttlWithMargin {
  composes: subttl;

  margin-top: 32px;
  margin-bottom: 8px;
}

.radioArea {
  display: flex;
  align-items: center;
  font-size: 14px;
  border: solid 1px #c4c4c4;
  border-radius: 8px;
  box-sizing: border-box;
  margin: 24px 0 0;
  padding: 0 16px;
  width: 100%;
  height: 40px;
  cursor: pointer;
  position: relative;
}

.radioArea:hover {
  background: #fff;
  border: solid 1px #9281ff;
  box-shadow: 0px 0px 8px rgba(146, 129, 255, 0.5);
  transition: 0.3s;
}

.radio {
  margin-right: 4px;
}

.expiry {
  color: #999;
  font-size: 12px;
  font-weight: normal;
  margin-left: 8px;
}

.delete {
  color: #c4c4c4;
  font-size: 12px;
  position: absolute;
  right: 16px;
  z-index: 99;
  text-decoration: none;
  height: 40px;
  display: flex;
  align-items: center;
  background: none;
  border: none;
}

.delete:hover {
  color: #2f80ed;
  text-decoration: underline;
}

.cards {
  display: flex;
  width: 100%;
  align-items: center;
  list-style: none;
  margin: 8px 0 0 0;
  padding: 0;
}

.label {
  display: block;
  font-size: 12px;
  font-weight: bold;
  margin: 8px 0 0;
}

.flex {
  display: flex;
  align-items: center;
  list-style: none;
  margin: 8px 0 0;
  box-sizing: border-box;
}

.left {
  width: 100%;
  margin-right: 24px;
}

.right {
  width: 100%;
}

.input {
  font-size: 14px;
  border: solid 1px #c4c4c4;
  border-radius: 8px;
  box-sizing: border-box;
  margin-top: 4px;
  padding: 0 16px;
  padding-top: 4px;
  width: 100%;
  height: 40px;
  position: relative;
  display: inline-flex;
  align-items: center;
}

.input:hover {
  background: #fff;
  border: solid 1px #9281ff;
  box-shadow: 0px 0px 8px rgba(146, 129, 255, 0.5);
  transition: 0.3s;
}

.input:focus {
  outline: none;
  background: #fff;
  border: solid 1px #9281ff;
  box-shadow: 0px 0px 8px rgba(146, 129, 255, 0.5);
  transition: 0.3s;
}

.inputNumber,
.inputDate,
.inputCvc {
  composes: input;
}

.inputDate {
  margin-right: 24px;
}

.inputArea {
  position: relative;
  margin: 16px 0 0 0;
  box-sizing: border-box;
}

.cards .icon img,
.cards .icon svg {
  max-width: 100%;
}

.icon {
  margin-top: 8px;
  margin-right: 8px;
  max-width: 63px;
  flex-shrink: 1;
}

.icon:last-child {
  margin-right: 0;
}

.txt {
  font-size: 14px;
  margin: 16px 0 0;
  text-align: center;
  line-height: 160%;
}

.txt[data-checked="true"] {
  color: #eb5757;
  white-space: pre-wrap;
}

.warning {
  font-size: 12px;
  line-height: 200%;
  color: #eb5757;
  margin-bottom: 0;
}

.btnWrap {
  margin: 48px auto 0;
}

.btn {
  background: #9281ff;
  border: 1px solid #9281ff;
  border-radius: 8px;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: bold;
  height: 40px;
  margin: 48px auto 0;
  padding: 0 16px;
  transition: 0.3s;
  width: 100%;
}

.btn svg {
  margin-right: 4px;
}

.btn svg {
  fill: #9281ff;
}

.btn:hover {
  opacity: 0.8;
  transition: 0.3s;
}

.btn:active {
  opacity: 0.3;
}

.btnSecondary {
  composes: btn;
  background: #fff;
  border-color: #c4c4c4;
  color: #333;
  transition: 0.3s;
}

.btnSecondary:hover {
  background-color: #f5f5f5;
  border-color: #f5f5f5;
  opacity: unset;
}

.border {
  display: flex;
  align-items: center;
  color: #999;
  font-size: 14px;
  margin: 32px 0 0;
}

.border:before {
  content: "";
  height: 1px;
  flex-grow: 1;
  background-color: #eaeaea;
  margin-right: 1rem;
}

.border:after {
  content: "";
  height: 1px;
  flex-grow: 1;
  background-color: #eaeaea;
  margin-left: 1rem;
}

.share {
  display: flex;
  align-items: center;
  justify-content: center;
  list-style: none;
  margin: 24px 0 0;
  padding: 0;
}

.share li {
  margin-left: 16px;
}

.share li:first-child {
  margin-left: 0;
}

.share li a {
  text-decoration: none;
}

.spi {
  display: none;
}

.couponDisabled {
  margin: 0;
  padding: 0;
  padding-top: 32px;
}

.couponArea {
  padding: 8px 0 0 0;
}

.couponHeader {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  padding-left: 8px;
}

.couponLabel {
  color: #999;
  font-size: 12px;
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  text-decoration: none;
}

.couponLabel:hover {
  color: #333;
  text-decoration: underline;
}

.couponHelp {
  cursor: pointer;
  text-decoration: none;
  margin-left: 8px;
}

.couponHelp svg {
  fill: #999;
}

.couponHelp:hover svg {
  fill: #333;
}

.couponAreaBody {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 32px;
}

.couponInput {
  composes: input;
  flex-grow: 1;
  font-family: monospace;
  height: 40px;
  padding-top: 0;
  margin: 0;
  margin-right: 4px;
}

.couponApplyBtn {
  flex-shrink: 0;
  flex-grow: 0;
}

@media screen and (max-width: 414px) {
  .box {
    box-sizing: border-box;
    padding: 16px 16px 32px;
    width: 100%;
    max-height: 70vh;
    overflow-y: scroll;
    overflow-x: hidden;
  }

  .genre {
    font-size: 12px;
  }

  .name {
    font-size: 16px;
  }

  .amount {
    font-size: 12px;
  }

  .price {
    font-size: 20px;
  }

  .dttl {
    font-size: 12px;
  }

  .list {
    font-size: 12px;
  }

  .radioArea {
    margin-top: 16px;
  }

  .inputArea {
    margin: 8px 0 0;
  }

  .inputArea:before,
  .inputArea:after {
    content: "";
  }

  .flex {
    display: block;
    padding: 0;
  }

  .btnWrap,
  .btn {
    margin-top: 32px;
  }

  .share {
    display: block;
    box-sizing: border-box;
    width: 100%;
    text-align: center;
  }

  .share li {
    margin-top: 16px;
    margin-left: 0;
  }

  .spi {
    display: inline-block;
  }
}
