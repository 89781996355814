.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 20000;
  overflow: hidden;

  display: flex;
  justify-content: center;
  align-items: center;

  background-color: rgba(30, 30, 30, 0.5);
  animation: fade 0.25s ease-out;
}

.body {
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 32px;
  max-width: 300px;

  display: flex;
  flex-direction: column;
  align-items: center;
}

.label {
  margin: 24px 0 0 0;
}

@keyframes fade {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
